import Vue from 'vue';
import {get} from "@/services/api";
import {feedBackIndex} from "@/services/endpoint.json";
import {userCan} from "@/utils";
import {mapMutations} from "vuex";
import {encrypt} from "@/helpers/encrypt";


export default Vue.extend({
  name: 'Feedback',
  components: {},
  data: () => ({
    arrayList: [],
    columns: [
      {key: 'nameCompany', label: 'Dealer', sortable: true,},
      {key: 'vin', label: 'VIN', sortable: true,},
      {key: 'calification', label: 'Rating Service', sortable: true,},
      {key: 'performance', label: 'Rating Performance', sortable: true,},
      {
        key: 'recomended', label: 'Recomended TVS', sortable: true, formatter: (value) => {
          return (Number(value) === 1) ? 'Yes' : 'No'
        }
      },
      {key: 'description', label: 'Review', sortable: true,},
      {key: 'created_at', label: 'Date', sortable: true,},
      {key: 'actions', label: 'Actions'}
    ],
    fields: [],
    dataFilter: '',
    canShowView: false
  }),
  mounted() {
    this.loadData().then()
    if (!userCan('administrator')) {
      this.columns.splice(0, 1)
      this.fields = this.columns;
    } else {
      this.fields = this.columns;
    }
    this.checkPermissions()
  },
  methods: {
    ...mapMutations(['loaderManager', 'notificationManager']),
    async loadData() {
      this.loaderManager(true);
      const {data} = await get(feedBackIndex, null, true)
      this.arrayList = data.message
      this.loaderManager(false)
    },
    async redirectToOrder(row) {
      const cryp = encrypt(JSON.stringify({id: row}))
      this.$router.push({name: 'order-detail', params: {id: cryp}}).then().catch()
    },
    checkPermissions() {
      if (this.$userCan(['distributor', 'dealer'])) {
        if (this.$store.state.user.subUser) {
          this.canShowView = this.$checkSubUserPermission('dashboard.view-detail')
        } else {
          this.canShowView = true
        }
      } else if (this.$userCan(['administrator'])) {
        this.canShowView = true
      }
    }
  },

});
